<template>
  <b-form-group
    label="Fornecedor"
    label-for="supplier"
  >
    <b-form-select
      id="supplier"
      v-model="selected"
      :state="selected === null ? false : true"
      :options="options"
      @change="(val) => $emit('update:item', val)"
    >
      <template #first>
        <b-form-select-option
          :value="null"
        >Por favor, selecione um fornecedor!</b-form-select-option>
      </template>
    </b-form-select>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormSelect, BFormSelectOption } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormSelect,
    BFormSelectOption
  },
  model: {
    prop: 'item',
    event: 'update:item'
  },
  props: {
    item: {
      required: true
    },
    suppliers: {
      type: Array,
      required: true
    },
    service: {
      required: true
    }
  },
  data: () => ({
    selected: null,
    options: []
  }),
  computed: {
    services () {
      return this.suppliers
        .flatMap(item => item.services)
        .filter(item => item.service_option_id === this.service)
    }
  },
  watch: {
    service () {
      const servicesID = this.services.map(item => item.supplier_id)

      if (this.services.length > 0) {
        this.getOptions(servicesID)
      } else {
        this.options = []
        this.selected = null
        this.$emit('update:item', null)
      }
    }
  },
  methods: {
    getOptions (id) {
      this.options = this.suppliers
        .filter(item => id.includes(item.id))
        .map(item => {
          return {
            value: item.id,
            text: item.name
          }
        })
    }
  }
}
</script>
