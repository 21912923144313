<template>
  <section>
    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="transparent"
      opacity="1"
      blur="2px"
      rounded="sm"
    >
      <b-card>
        <b-card-title class="d-flex justify-content-center">
          Contas
        </b-card-title>
        <b-card-text>
          <b-row>
            <b-col cols="12">
              <b-form-select
                id="service-type"
                v-model="selected"
                :state="selected === null ? false : true"
                :options="options"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                  >Por favor, selecione um tipo!</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>

            <b-col
              cols="12"
              class="mt-2"
            >
              <b-form-file
                ref="refInputEl"
                v-model="formData.file"
                placeholder="Selecione um arquivo de texto"
                drop-placeholder="Solte aqui o arquivo"
                browse-text="Procurar"
              />
            </b-col>

            <b-col
              cols="12"
              class="mt-2"
            >
              <supplier-select
                v-model="supplier_selected"
                :suppliers="suppliers"
                :service="selected"
              />
            </b-col>

            <b-col
              cols="12"
              class="mt-1 d-flex justify-content-center"
            >
              <b-button
                :disabled="loading"
                variant="relief-primary"
                @click.stop="send()"
              >
                <span v-if="!loading">Enviar</span>
                <span v-else>
                  <b-spinner small />
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BFormSelect,
  BFormSelectOption,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BSpinner,
  BOverlay
} from 'bootstrap-vue'
import SupplierSelect from './SupplierSelect.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BSpinner,
    BOverlay,
    SupplierSelect
  },
  data: () => ({
    loading: false,
    formData: {
      file: null
    },
    items: [],
    options: [],
    selected: null,
    suppliers: [],
    supplier_selected: null
  }),
  mounted () {
    this.getAccountOptions()
  },
  methods: {
    async getAccountOptions () {
      await this.$http
        .get('/admin/service_options/filter_per_type/4')
        .then(res => {
          this.items = res.data
          this.options = res.data.map(item => {
            return {
              value: item.id,
              text: item.name
            }
          })
        })
        .finally(() => {
          const id = this.$router.currentRoute.query.id
          if (id !== undefined) {
            this.selected = id
          }

          this.getSuppliers()
        })
    },
    async getSuppliers () {
      await this.$http
        .get('/admin/get_full_data_suppliers')
        .then(res => {
          this.suppliers = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    async send () {
      this.loading = true
      const params = new FormData()
      params.append('service_option_id', this.selected)
      params.append('file', this.$refs.refInputEl.files[0])

      if (this.supplier_selected !== null) params.append('supplier_id', this.supplier_selected)

      await this.$http
        .post('/admin/pack_of_photos', params)
        .then(res => {
          this.$swal({
            title: 'As contas foram armazenadas com sucesso!',
            html: `Total: ${res.data.total} <br /> <br />
             Armazenados: ${res.data.uploaded} <br /> <br />
             Duplicados: ${res.data.duplicates} <br /> <br />
             Resumo: ${res.data.text}`,
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'OK!',
            cancelButtonText: 'Fechar',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            },
            buttonsStyling: false
          })
        })
        .catch(() => {
          this.$swal({
            title: 'Ops! Parece que algo de errado não está certo!',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK! Entendi!',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
